//
//
//  Home
//
//

import {useLogto} from "@logto/react"
import {useEffect, useState} from "react"


function Home() {
    const [userName, setUserName] = useState("")
    const { signIn, signOut, isAuthenticated, getIdTokenClaims, getAccessToken} = useLogto()

    useEffect(() => {
        if (isAuthenticated) {
            getIdTokenClaims().then((claims) => {
                setUserName(claims?.name || "")
            })
        }
    }, [getIdTokenClaims, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            getAccessToken("https://play.canaia.ai/api").then(token => {
                fetch(import.meta.env.VITE_API_ENDPOINT + "/api/ping", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => console.log(response))
            })
        }
    }, [isAuthenticated, getAccessToken])

    if (isAuthenticated) {
        return (
            <>
                <h1>Hello {userName}</h1>
                <button onClick={() => signOut(window.location.origin + "/")}>Sign Out</button>
            </>
        )
    } else {
        return (
            <button onClick={() => signIn(window.location.origin + '/sign-in-callback')}>Sign In</button>
        )
    }
}

export default Home
